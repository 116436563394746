import "./src/styles/global.css";
import "@fontsource/raleway";

import React from "react";
import Layout from "./src/components/Layout";

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>;
};
