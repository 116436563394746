import React from "react";
import { Link, withPrefix } from "gatsby";
import useStore from "../state";
import { MoonIcon } from "@heroicons/react/solid";
import { MoonIcon as MoonIconOutline } from "@heroicons/react/outline";

const Layout = ({ children, location }) => {
    const isHomepage = location.pathname === withPrefix("/");
    const { status, set } = useStore((state) => state.darkMode);

    return (
        <main
            className={`${
                status ? "dark bg-gray-800 text-red-50" : "light"
            } overflow-auto w-screen h-screen mx-auto`}
        >
            {children}
            {!isHomepage && (
                <Link
                    className={`${
                        status ? "bg-gray-800" : "bg-white"
                    } fixed rounded-br-lg top-0 left-0 p-4 underline`}
                    to="/"
                >
                    Retour
                </Link>
            )}
            <ul
                className={`${
                    status ? "bg-gray-800" : "bg-white"
                } fixed rounded-tr-lg left-0 bottom-0 p-2 flex flex-row`}
            >
                <li>
                    {!status ? (
                        <MoonIcon
                            className="h-7 w-7 left-10 cursor-pointer hover:opacity-75"
                            onClick={() => set(!status)}
                        />
                    ) : (
                        <MoonIconOutline
                            className="h-7 w-7 left-10 cursor-pointer hover:opacity-75"
                            onClick={() => set(!status)}
                        />
                    )}
                </li>
            </ul>
            {isHomepage && (
                <footer
                    className={`${
                        status ? "bg-gray-800" : "bg-white"
                    } rounded-tl-lg fixed right-0 bottom-0 p-2`}
                >
                    <span>Nous sommes en </span>
                    <span>{new Date().getFullYear()}</span>
                </footer>
            )}
        </main>
    );
};

export default Layout;
