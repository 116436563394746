import create from "zustand";

export default create((s, g) => ({
    darkMode: {
        status: false,
        set: (status) =>
            s((state) => ({
                ...state,
                darkMode: { ...state.darkMode, status },
            })),
    },
    nav: {
        isOpen: false,
        toggle: () =>
            s((state) => ({
                ...state,
                nav: { ...state.nav, isOpen: !state.nav.isOpen },
            })),
    },
}));
